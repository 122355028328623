.deployment-management-environment-table table {
  border: 1px solid #babcbe;
}

.deployment-management-environment-table .environment-table-headers div {
  font-size: 0.9rem;
}

.deployment-management-status .status-table-headers div {
  font-size: 0.9rem;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc;
}
