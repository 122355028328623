.questions-grid {
  display: grid;
  grid-template-columns:
    20px
    minmax(75px, 120px)
    minmax(70px, 90px)
    minmax(120px, 2fr)
    minmax(90px, 1fr)
    minmax(90px, 1fr)
    minmax(50px, 1fr)
    minmax(90px, 1fr);
  gap: 16px;
}

.answers-grid {
  display: grid;
  grid-template-columns:
    20px
    minmax(75px, 120px)
    minmax(70px, 90px)
    minmax(110px, 2fr)
    minmax(130px, 2fr)
    minmax(160px, 2fr)
    minmax(50px, 1fr);
  gap: 16px;
}
