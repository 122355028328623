div.jsoneditor,
div.jsoneditor-menu {
  border-color: #4b4b4b;
}

div.jsoneditor-menu {
  @apply bg-blue-800 text-white;
}

div.jsoneditor-tree,
div.jsoneditor textarea.jsoneditor-text {
  @apply bg-white;
}

div.jsoneditor-field,
div.jsoneditor-value {
  @apply text-gray-800;
}

div.jsoneditor-field[contenteditable='false'] {
  @apply text-gray-400;
}

table.jsoneditor-search div.jsoneditor-frame {
  @apply bg-blue-800 text-white;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  @apply bg-gray-50 text-white;
}

div.jsoneditor-field[contenteditable='true']:focus,
div.jsoneditor-field[contenteditable='true']:hover,
div.jsoneditor-value[contenteditable='true']:focus,
div.jsoneditor-value[contenteditable='true']:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  @apply bg-blue-800 text-white;

  border-color: #808080;
}

div.jsoneditor-field.highlight-active,
div.jsoneditor-field.highlight-active:focus,
div.jsoneditor-field.highlight-active:hover,
div.jsoneditor-value.highlight-active,
div.jsoneditor-value.highlight-active:focus,
div.jsoneditor-value.highlight-active:hover {
  @apply bg-blue-800;

  border-color: #b1b1b1;
}

table.jsoneditor-tree > tbody > tr.jsoneditor-expandable:first-child {
  display: none;
}

button.jsoneditor-transform {
  @apply hidden;
}

div.jsoneditor-tree button:focus {
  @apply bg-blue-800 text-white;
}

div.jsoneditor-readonly {
  color: #acacac;
}

div.jsoneditor td.jsoneditor-separator {
  color: #acacac;
}

div.jsoneditor-value.jsoneditor-string[contenteditable='false'] {
  @apply text-gray-300;
}

div.jsoneditor-value.jsoneditor-string {
  @apply text-gray-500;
}

div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  color: #bababa;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ff4040;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8048;
}

div.jsoneditor-value.jsoneditor-null {
  color: #49a7fc;
}

div.jsoneditor-value.jsoneditor-invalid {
  color: white;
}

td .jsoneditor-dragarea {
  @apply hidden !important;
}
