@import url('tailwindcss/utilities.css');
@import url('tailwindcss/base.css');

#root input[type*='date'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select,
textarea {
  vertical-align: unset;
  line-height: unset;
  min-height: unset;
  color: unset;
  border-radius: unset;
  outline: none;
  width: unset;
}

#root ul li,
#headlessui-portal-root ul li {
  margin-bottom: unset;
}

#root button,
button[type='reset'],
input[type='reset'],
button[type='reset']:hover,
button[disabled],
button[disabled]:hover,
input[type='button'][disabled],
input[type='button'][disabled]:hover,
input[type='reset'][disabled],
input[type='reset'][disabled]:hover,
input[type='submit'][disabled],
input[type='submit'][disabled]:hover,
button[type='submit'] {
  font-size: unset;
  line-height: unset;
  min-height: unset;
  display: unset;
  vertical-align: unset;
  border-radius: unset;
  box-sizing: unset;
  font-weight: unset;
  text-decoration: unset;
  color: unset;
  background-color: unset;
  border: unset;
}

#headlessui-portal-root button {
  font-size: unset;
  line-height: unset;
  min-height: unset;
  display: unset;
  vertical-align: unset;
  border-radius: unset;
  box-sizing: border-box;
  font-weight: unset;
  text-decoration: unset;
}

#root span,
td,
div {
  font-size: 1rem;
}

#root label,
#headlessui-portal-root label {
  margin: 0;
}

#headlessui-portal-root .jsoneditor-text {
  color: #1a1a1a;
  font-size: 14px;
}

#headlessui-portal-root .jsoneditor-frame {
  display: flex;
}

#questions-and-announcements-root p {
  margin: 1rem 0;
}

#questions-and-announcements-root button,
#questions-and-announcements-root button:focus {
  min-height: unset;
  color: unset;
}

#questions-and-announcements-root button:hover {
  background-color: white;
  color: unset;
}

#questions-and-announcements-root button.active {
  background-color: #003468;
  color: white;
}

#questions-and-announcements-root [class*='QuantityPicker'].active label {
  color: white;
}

#questions-and-announcements-root [class*='NestedButtonContainer'] [class*='AnswerButton'].active {
  background-color: #fff0c3;
}

#questions-and-announcements-root [class*='AnswerLabel'].active {
  color: white;
}
