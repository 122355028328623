/* style.css */
@import url('./reset-mui.css');
@import url('tailwindcss/base.css');
@import url('tailwindcss/components.css');
@import url('tailwindcss/utilities.css');

.react-select__control,
.multi-select__control {
  min-height: 40px;
  border-color: hsl(0deg 0% 70%) !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.react-select__control:focus-within,
.multi-select__control:focus-within {
  border-color: #2372b8 !important;
}

.react-select__input:focus,
.multi-select__input:focus {
  --tw-ring-shadow: none !important;
  --tw-ring-color: none !important;
}

.multi-select__value-container {
  flex-wrap: wrap;
}

html {
  font-size: 16px !important;
}
